// import { useCallback } from 'react';
// import { useSiteContext } from './LayoutProvider';
import { Section } from '@/Section';
import { Nav } from '@/layout/Nav';
import { Footer } from '@/layout/Footer';
import { Flex } from '@/Flex';
import { Title } from '@/Title';
import { Margin } from '@/layout/Margin';
import { StaticImage } from 'gatsby-plugin-image';
import { ContactForm } from '@/layout/Contact-Form';
import styled from '@emotion/styled';
// import { ImageGrid } from '@/Image-Grid';
import { Map } from '@/Map';
// import MediaQuery from 'react-responsive';
import { Copy } from '@/Copy';
import { LocationLink } from '@/layout/Location-Link';
import { Hours } from '@/Hours';
import { Link } from 'gatsby';
import { ButtonPrimary } from '@/layout/StyledComponents';

const StyledLayout = styled.main`
    .special-title-container {
        width: 390px;
        &::before {
            position: relative;
            top: 20px;
            min-height: 52px;
        }
    }
    .form-image {
        border-radius: 512px;
        img {
            border-radius: 512px;
        }
    }
    .special-title {
        position: relative;
        bottom: 24px;
    }
    @media ${({ theme }) => theme.device.laptop} {
        .special-title {
            position: relative;
            bottom: -16px;
            width: auto;
            ::before {
                min-height: 52px;
            }
        }
        .form-image {
            flex-shrink: 0;
            box-sizing: border-box;
            border-radius: 512px;
            margin: 0;
            padding: 0;
        }
        .form {
            min-width: 46.6%;
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }
    }
`;

export const DefaultLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    // const { siteState, setSiteState } = useSiteContext();

    // const toggleLanguage = useCallback(() => {
    //     setSiteState(s => ({ ...s, language: s.language === 'en' ? 'es' : 'en' }));
    // }, [setSiteState]);

    return (
        <StyledLayout>
            <Nav />
            {children}
            <Section background="white">
                <Flex equal gap={108}>
                    <Title className="special-title-container" position="left" border color="red">
                        <span className="special-title">Where Smiles Shine Brighter</span>
                    </Title>
                    <Copy>
                        <p>
                            {`Experience compassionate care and cutting-edge treatments tailored to
                            your family's dental needs.`}
                        </p>
                        <ButtonPrimary color="red" solid>
                            <Link to="#contact">CONTACT US</Link>
                        </ButtonPrimary>
                    </Copy>
                </Flex>
            </Section>
            <Section background="white">
                <Flex equal reverse gap={108}>
                    <div>
                        <Copy>
                            <Title position="left" border color="red" sub>
                                Reach Out To Us
                            </Title>
                            <LocationLink color="black" type="email" />
                            <LocationLink color="black" type="phone" />
                            <LocationLink color="black" type="map" />
                        </Copy>
                        <br />
                        <Copy>
                            <Title position="left" border color="red" sub>
                                Working Hours
                            </Title>
                            <Hours>
                                <ul>
                                    <li>Monday:</li>
                                    <li>Tuesday:</li>
                                    <li>Wednesday:</li>
                                    <li>Thursday:</li>
                                    <li>Friday:</li>
                                    <li>Saturday:</li>
                                    <li>Sunday:</li>
                                </ul>
                                <ul>
                                    <li>9:00 AM - 5:00 PM</li>
                                    <li>9:00 AM - 5:00 PM</li>
                                    <li>9:00 AM - 5:00 PM</li>
                                    <li>9:00 AM - 5:00 PM</li>
                                    <li>8:00 AM - 1:00 PM</li>
                                    <li>
                                        <span>Closed</span>
                                    </li>
                                    <li>
                                        <span>Closed</span>
                                    </li>
                                </ul>
                            </Hours>
                        </Copy>
                    </div>
                    <Map>
                        <iframe
                            title="main family dentistry"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12927.443324321412!2d-81.539655!3d35.901424!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8850df16acec2b07%3A0x13bcb72c5f64dd1d!2sWestern%20Carolina%20Family%20Dentistry!5e0!3m2!1sen!2sus!4v1712759669453!5m2!1sen!2sus"
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </Map>
                </Flex>
            </Section>
            {/* <ImageGrid>
                <MediaQuery maxWidth={768}>
                    <StaticImage
                        src="../images/home/image-gallery-mobile.jpg"
                        placeholder="none"
                        quality={100}
                        alt="grid of staff members"
                    />
                </MediaQuery>
                <MediaQuery minWidth={768}>
                    <StaticImage
                        src="../images/home/image-gallery-desktop.jpg"
                        placeholder="none"
                        quality={100}
                        alt="grid of staff members"
                    />
                </MediaQuery>
            </ImageGrid> */}
            <Section background="white">
                <Flex width="narrow" center equal gap={32}>
                    <div className="form">
                        <Title position="left" border color="red">
                            Contact Us
                        </Title>
                        <ContactForm />
                    </div>
                    <Margin />
                    <StaticImage
                        className="form-image"
                        src="../images/woman-smiling.jpg"
                        placeholder="none"
                        quality={100}
                        alt="woman holding sign up smiling"
                    />
                </Flex>
            </Section>
            <Section background="dark">
                <Footer>
                    <Flex gap={124}>
                        <div>
                            <Title position="left" color="red">
                                Discover
                            </Title>
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/patient-resources/new-patients/">
                                        Financing Options
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/patient-resources/faq/">FAQ</Link>
                                </li>
                                {/* <li>Blog</li> */}
                            </ul>
                        </div>
                        <div>
                            <Title position="left" color="red">
                                About Us
                            </Title>
                            <ul>
                                <li>
                                    <Link to="/about-us/our-practice/">OUR PRACTICE</Link>
                                </li>
                                <li>
                                    <Link to="/about-us/our-team/">OUR TEAM</Link>
                                </li>
                                {/* <li>Privacy policy</li> */}
                            </ul>
                        </div>
                        <div>
                            <Title position="left" color="red">
                                Popular Services
                            </Title>
                            <ul>
                                <li>
                                    <Link to="/services/general-dentistry/fillings/">FILLINGS</Link>
                                </li>
                                <li>
                                    <Link to="/services/restorative-dentistry/dental-bridges/">
                                        DENTAL BRIDGES
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services/general-dentistry/root-canal/">
                                        ROOT CANALS
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services/general-dentistry/wisdom-tooth-extraction/">
                                        WISDOM TEETH EXTRACTIONS
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Flex>
                </Footer>
            </Section>
        </StyledLayout>
    );
};
