export const navMenu = [
    {
        name: 'About Us',
        path: '',
        menu: [
            { name: 'Our Practice', path: '/about-us/our-practice/' },
            { name: 'Our Team', path: '/about-us/our-team/' },
        ],
    },
    {
        name: 'Services',
        path: '',
        menu: [
            {
                name: 'General Dentistry',
                sub_menu: [
                    {
                        name: 'Fillings',
                        path: '/services/general-dentistry/fillings/',
                    },
                    {
                        name: 'Root Canals',
                        path: '/services/general-dentistry/root-canal/',
                    },
                    {
                        name: 'Wisdom Tooth Extraction',
                        path: '/services/general-dentistry/wisdom-tooth-extraction/',
                    },
                    {
                        name: 'General Tooth Extractions',
                        path: '/services/general-dentistry/general-tooth-extraction/',
                    },
                ],
            },
            {
                name: 'Restorative Dentistry',
                sub_menu: [
                    {
                        name: 'Dental Bridges',
                        path: '/services/restorative-dentistry/dental-bridges/',
                    },
                    {
                        name: 'Porcelain Crowns',
                        path: '/services/restorative-dentistry/porcelain-crowns/',
                    },
                ],
            },
            {
                name: 'Dentures',
                sub_menu: [
                    {
                        name: 'Partial Dentures',
                        path: '/services/dentures/partial-dentures/',
                    },
                    {
                        name: 'Flexible-Base-Dentures',
                        path: '/services/dentures/flexible-based-dentures/',
                    },
                ],
            },
        ],
    },
    {
        name: 'Patient Resources',
        path: '',
        menu: [
            { name: 'Accessibility', path: '/patient-resources/accessibility/' },
            {
                name: 'FAQ',
                path: '/patient-resources/faq/',
            },
            { name: 'New Patients', path: '/patient-resources/new-patients/' },
            { name: 'Financing Options', path: '/patient-resources/financing-options/' },
            { name: 'Privacy Policy', path: '/patient-resources/privacy-policy/' },

            // { name: 'Blog', path: '/blog/' },
        ],
    },
];
