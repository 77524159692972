import React from 'react';
import styled from '@emotion/styled';
import { theme } from './layout/GlobalStyles';

const StyledCopy = styled.div<{ small?: boolean }>`
    p {
        font-size: ${({ small }) => (small ? `14px` : `20px`)};
    }
    button {
        margin-top: 32px;
    }

    @media ${theme.device.tablet} {
    }
    @media ${theme.device.laptop} {
        p {
            font-size: ${({ small }) => (small ? `14px` : `24px`)};
        }
    }
    @media ${theme.device.desktop} {
        max-width: 740px;

        p {
            font-size: ${({ small }) => (small ? `16px` : `26px`)};
        }
    }
`;

interface Props {
    children: React.ReactNode;
    small?: boolean;
    className?: string;
}

export const Copy: React.FunctionComponent<Props> = ({ children, small, className }) => {
    return (
        <StyledCopy small={small} className={className}>
            {children}
        </StyledCopy>
    );
};

const StyledTemplateCopy = styled.div<{ small?: boolean }>`
    h2 {
        color: ${({ theme }) => theme.colors.darkmodebg};
        margin-bottom: 24px;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    ul {
        padding-left: 32px;
        margin-bottom: 24px;
        li {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    a {
        color: ${({ theme }) => theme.colors.yellow1};
    }
    p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .image {
        margin-bottom: 40px;
        img {
            border-radius: 16px;
        }
    }

    @media ${theme.device.laptop} {
        width: 75vw;
        margin: 0 auto;
        max-width: 1240px;
        display: flex;
        flex-direction: column;
        align-items: start;
        a {
            &:hover {
                text-decoration: underline;
            }
        }
        h2 {
            font-size: 36px;
            font-style: normal;
            margin-top: 12px;
        }
        .image {
            width: 67.5vw;
            max-width: 1000px;
            margin: 48px 0;
            max-height: 450px;
        }
        .image {
            align-self: center;
        }
    }
    @media ${({ theme }) => theme.device.desktop} {
        .image {
            max-height: 550px;
        }
    }
`;

export const TemplateCopy: React.FunctionComponent<Props> = ({ children, small, className }) => {
    return (
        <StyledTemplateCopy small={small} className={className}>
            {children}
        </StyledTemplateCopy>
    );
};
